<template>
  <div :key="id">
    <div v-if="!dates" class="flex flex-center flix-flex flix-flex-center">
      <b-flix-loader></b-flix-loader>
    </div>
    <div v-if="dates !== false" :key="updateKey" class="flix-form-group" style="max-width: 780px; margin: 0 auto;">
    <div v-if="saving" class="flix-text-center flex flex-center">
      <savingAnimation @save="saving=false"></savingAnimation>
    </div>
    <div :key="updateKey" v-if="dates && !saving">
    <b-flix-datemanager @save="setSave" @changeMonth="changeMonth" :dates="dates" :settings='{"startMonth": this.startMonth, "hover": true, "calendar":{"shadow":false,"navigation":true,"style":"rounded"},"color":"#bf360c","language":$i18n.locale,"presets":{"allDay":"Freier Tag","hours":"Mehrstündige Pause","fromTo":"Mehrtägige Abwesenheit / Urlaub"},"functions":{"edit":"bearbeiten","allDay":"Freier Tag","hours":"Mehrstündige Pause","fromTo":"Mehrtägige Abwesenheit / Urlaub"}}'>
      <div slot="top">
        <calendarSelector :id="id" @save="changeCalendar">
        <span slot="allCalendar">{{ $t('message.allCalendar') }}: {{ $tc('message.holidays', 2) }}</span>
        </calendarSelector>
        <b-flix-h3 style="color: #bf360c">{{ $tc('message.holidays', 2) + ' &amp; ' + $tc('message.vacations', 2) }}</b-flix-h3>
    </div>
      <div slot="bottom">
        <b-flix-icon icon="circle-empty-info"></b-flix-icon> {{ $t('message.clickonDayForActivateOrDeaktivate') }}
      </div>
    </b-flix-datemanager>
    <div v-if="id === '*'" class="flix-alert flix-alert-warning">
      {{ $t('message.holidaysAllCalendar') }}
    </div>
    </div>
    </div>
    <div class="flix-form-group flix-text-center" v-show="!holidayActive">
      <div v-if="!id">
        <addBtn :onClick="function () { setStandardHolidays() }"><template v-slot:text>{{ $t('message.holidayGerman', {year: new Date().getFullYear()}) }}</template></addBtn>
      </div>
    </div>
  </div>
</template>
<script>
const holidaysJS = require('./holidays.js')
export default {
  components: {
    savingAnimation () {
      return import('./savingAnimation')
    },
    calendarSelector () {
      return import('./selector')
    }
  },
  props: { },
  data () {
    return {
      id: this.getID(),
      dates: false,
      startMonth: this.$store.state.assistents.currentMonth,
      holidayActive: false,
      updateKey: 1,
      saving: false
    }
  },
  methods: {
    changeCalendar (ret) {
      this.id = ret
      this.getData()
    },
    changeMonth (date) {
      this.startMonth = date
      this.$store.commit('setAssistentCurrentMonth', date)
    },
    getID () {
      if (this.$store.state.assistents.assistentActive) {
        return this.$store.state.assistents.assistentActive.ID
      } else if (typeof this.$store.getters.assistents[0] === 'object') {
        return this.$store.getters.assistents[0].ID
      } else {
        return '*'
      }
    },
    getFilterDates () {
      var filter = []
      this.dates.forEach((d) => {
        if (typeof this.id === 'undefined' || d.to === '*' || String(d.to) === String(this.id)) {
          filter.push(d)
        }
      })
      this.dates = filter
      this.updateKey++
    },
    setStandardHolidays () {
      this.$flix_post({
        url: 'holidays/standard',
        data: {
          user: this.$store.getters.user.md5_id,
          filter: this.id || '*'
        },
        callback: function () {
          this.getData()
        }.bind(this)
      })
    },
    newData () {
      var d = new Date()
      d.setDate(d.getDate() + 1)

      var to = '*'
      if (typeof this.id !== 'undefined') {
        to = this.id
      }

      this.holidayActive = {
        to: to,
        begin: d.getFullYear() + '-' + this.$getNullsBefore((d.getMonth() + 1)) + '-' + this.$getNullsBefore(d.getDate()) + ' 00:00:00',
        end: d.getFullYear() + '-' + this.$getNullsBefore((d.getMonth() + 1)) + '-' + this.$getNullsBefore(d.getDate()) + ' 23:59:59',
        title: this.$tc('message.vacations', 1) + ' ' + d.getTime(),
        status: 'active'
      }
    },
    setOrder (unordered) {
      var ordered = {}
      Object.keys(unordered).sort().forEach(function (key) {
        ordered[key] = unordered[key]
      })

      return Object.values(ordered)
    },
    async setSave (data) {
      this.saving = true
      const newData = JSON.parse(JSON.stringify(data))
      const handleData = {
        assistentsID: Object.keys(this.$store.state.assistents.assistentsByID),
        oldData: this.dates,
        newData: newData,
        saveData: this.axiosSave,
        flixPost: this.$flix_post,
        user: this.$store.getters.user.md5_id,
        filter: this.id || '*'
      }
      this.dates = false

      const savedData = await holidaysJS(handleData)
      this.dates = savedData[1]
      this.$emit('save')
      this.updateKey++
    },
    getDate (d) {
      return [this.$createDate(d.begin), this.$createDate(d.end)]
    },
    getData () {
      this.dates = false
      this.$flix_post({
        url: 'holidays/get',
        data: {
          user: this.$store.getters.user.md5_id,
          filter: this.id || '*'
        },
        callback: (ret) => {
          if (ret.data[0]) {
            this.dates = ret.data[1]
            this.getFilterDates()
          } else {
            this.dates = []
            this.getFilterDates()
          }
        }
      })
    }
  },
  mounted () {
    this.getData()
  }
}
</script>
<style lang="sass" scoped>
  .flix-bg-overflow
    padding: 20px
    box-sizing: border-box
    overflow-x: auto
    overflow-y: hidden
    white-space: nowrap
    width: 100%
  .flix-inactive
    // opacity: 0.5
  .flix-active
    // opacity: 1
</style>
